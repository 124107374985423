import BottomFlowSection from './BottomFlowSection';
import { setName } from '../redux_slices/currentRequestSlice';
import { useDispatch, useSelector } from 'react-redux';

const FlowName2 = ({step}) => {
    const dispatch = useDispatch();
  const name = useSelector((state) => state.currentRequest.name);

    const handleNameChange = (event) => {
        dispatch(setName(event.target.value));
    };

    return (
        <div dir="rtl" className="max-w-2xl mx-auto mt-16 px-4 flex flex-col">
            <p dir="rtl" className="text-right text-xl mx-4"> מה שמך?</p>
            <input
                type="text"
                value={name}
                onChange={handleNameChange}
                placeholder="שם"
                className="w-full mt-4 border border-gray-200 border-2 rounded-lg"
                maxLength={100}
            />
            <BottomFlowSection step={step} isNextDisabled={!name}/>
        </div>
    );
};

export default FlowName2;