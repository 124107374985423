import { API_HOST } from "../config/config";

export async function getLastRequests(authToken) {
  const response = await fetch(`${API_HOST}/requests/last`, {
    headers: {
        'Authorization': `Bearer ${authToken}`,

    },
  });
  const data = await response.json();
  return data;
}

export const fetchRequestById = async (authToken, requestId) => {
  const response = await fetch(`${API_HOST}/requests?requestId=${requestId}`,{
    headers: {
      'Authorization': `Bearer ${authToken}`,

  },
  }); // Adjust the API endpoint
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
};