import { useNavigate } from "react-router-dom";
import { setContent } from "../redux_slices/currentRequestSlice";
import BottomFlowSection from "./BottomFlowSection";
import { useDispatch, useSelector } from "react-redux";

export default function FlowDescription1({step}) {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.currentRequest.content);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  if(user){
    navigate("/pros_list");
  }
  const handleTextChange = (event) => {
    dispatch(setContent(event.target.value)); // Dispatch the action
  };

  return (
    <div dir="rtl" className="max-w-2xl mx-auto mt-16 px-4 flex flex-col">
      <div className="text-right text-2xl font-bold mx-4">
        מה עוד אנחנו צריכים לדעת כדי לספק לך את השירות הטוב ביותר?
      </div>
      <textarea
        className={`w-full mt-4 rounded-lg border ${!content ? 'border-red-500' : 'border-gray-200'}`}
        rows="16"
        value={content}
        onChange={handleTextChange}
        required
        maxLength={1000}

      ></textarea>
      <BottomFlowSection step={step} isNextDisabled={!content} />
    </div>
  );
}
