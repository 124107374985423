import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { getLastRequests } from "../fetch/LastRequestsFetch";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentRequest } from "../redux_slices/currentRequestSlice";

const prefix = "https://bidme-pub.s3.amazonaws.com";

function Media() {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");
  React.useEffect(() => {
    getLastRequests(authToken)
      .then((data) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, []);

  const handleItemClick = (item) => {
    console.log("item", item);
    dispatch(setCurrentRequest(item));
    navigate("/pros_list?requestid="+item.requestId);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="row-reverse"
      justifyContent="center"
    >
      {(loading ? Array.from(new Array(3)) : data).map((item, index) => (
        <Box
          key={index}
          sx={{ width: 210, marginRight: 1.5, my: 5 }}
          onClick={() => handleItemClick(item)}
        >
          {" "}
          {item ? (
            <img
              style={{ width: 210, height: 118, cursor: "pointer" }}
              alt={item.subCategory}
              src={`${prefix}/${item.categoryId}.jpg`}
            />
          ) : (
            <Skeleton variant="rectangular" width={210} height={118} />
          )}
          {item ? (
            <Box dir="rtl" sx={{ pr: 2 }}>
              <Typography gutterBottom variant="body2">
                {item.subCategory} {"ב-"} {JSON.parse(item.area).cityName}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="text.secondary"
              >
                {"נשלח ל-"}
                {item.sent} {"בעלי מקצוע מתאימים"}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {item.created}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          )}
        </Box>
      ))}
    </Grid>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function YouTube() {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Media />
    </Box>
  );
}
