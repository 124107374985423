import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Select from "react-select";

import TextAreaRequired from "./TextAreaRequired";
import {
  setArea,
  setAreaId,
  setCategory,
  setCategoryId,
  setContent,
  setCurrentRequest,
  setPhone,
  setSubCategory,
} from "../redux_slices/currentRequestSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { saveRequest } from "../fetch/SaveRequestFetch";
import { useNavigate } from "react-router-dom";
import { API_HOST } from "../config/config";
import { fetchRequestById } from "../fetch/LastRequestsFetch";
import { fetchCategories } from "../fetch/CategoriesFetch";

const ProjectSummary = () => {
  const [allCities, setAllCities] = useState([]);
  const currentRequest = useSelector((state) => state.currentRequest);
  const area = useSelector((state) => state.currentRequest.area);
  const user = useSelector((state) => state.user);
  console.log(currentRequest.requestId);
  const [projectDetails, setProjectDetails] = useState({
    title: "פרטי הפרויקט שלך:",
    name: "",
    category: currentRequest.category,
    subCategory: currentRequest.subCategory,
    email: "",
    address: currentRequest.area,
    phoneNumber: "",
    additionalInfoTitle:
      "מידע נוסף שצריך לדעת כדי לספק לך את השירות הטוב ביותר:",
    additionalInfo: currentRequest.content,
  });
  
  const [editable, setEditable] = useState(
    !currentRequest || !currentRequest.content || currentRequest.content === ""
  );
  
  
  const dispatch = useDispatch();  
  
  useEffect(() => {    
    const cities = sessionStorage.getItem("cities");
    if(cities && cities.length > 0){
      setAllCities(JSON.parse(cities));
    }
    else{
      const fetchCities = async () => {
        try {
          const response = await fetch(`${API_HOST}/cities`);
          if (response.ok) {
            const data = await response.json();
            if (data.data && Array.isArray(data.data)) {
              setAllCities(data.data);
              sessionStorage.setItem("cities", JSON.stringify(data.data));
            }
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };
  
      fetchCities();      
    }
    if (user) {
      if (!currentRequest.area) dispatch(setArea(user.area));
      if (!currentRequest.phone) dispatch(setPhone(user.phoneNumber));
  
      setProjectDetails({
        title: "פרטי הפרויקט שלך:",
        name: user.firstName,
        category: currentRequest.category,
        subCategory: currentRequest.subCategory,
        categoryId: currentRequest.categoryId,
        email: user.email,
        address: currentRequest.area ? currentRequest.area : "",
        phoneNumber: currentRequest.phone ? currentRequest.phone : "",
        additionalInfoTitle:
          "מידע נוסף שצריך לדעת כדי לספק לך את השירות הטוב ביותר:",
        additionalInfo: currentRequest.content,
      });
    } else {
      setProjectDetails({
        title: "פרטי הפרויקט שלך:",
        name: "",
        category: currentRequest.category,
        subCategory: currentRequest.subCategory,
        categoryId: currentRequest.categoryId,
        email: "",
        address: currentRequest.area,
        phoneNumber: "",
        additionalInfoTitle:
          "מידע נוסף שצריך לדעת כדי לספק לך את השירות הטוב ביותר:",
        additionalInfo: currentRequest.content,
      });
      setEditable(!currentRequest.content || currentRequest.content === "" );
    }
  }, [projectDetails.additionalInfo, currentRequest.area, currentRequest.phone, user, dispatch, currentRequest.category, currentRequest.categoryId, currentRequest.content, currentRequest.subCategory]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const requestId = urlParams.get('requestId');
    const category = urlParams.get('category');
    const areaId = urlParams.get('areaId');

    console.log('URL:', window.location.search); // Log the URL
    console.log('Extracted requestId:', requestId); // Log the extracted requestId
    const authToken = localStorage.getItem("authToken");
    if (authToken && !currentRequest.requestId && requestId) {
      fetchRequestById(authToken, requestId)
        .then((data) => {
          dispatch(setCurrentRequest(data.data));
          if(category){
            const categoryInt = parseInt(category);
            console.log("categoryInt", categoryInt);
            fetchCategories().then((fetched) => {              
                const categoryOpt = fetched.find(
                  (category) =>{
                    console.log("category.id", category.id);
                     return category.id === categoryInt;
                  }
                );
        
                if (categoryOpt) {
                  const categoryOption = {
                    category: categoryOpt.category,
                    subCategory: categoryOpt.subCategory,
                    label: categoryOpt.subCategory,
                    categoryId: categoryOpt.id,
                  };
                  dispatch(setCategory(categoryOption.category));
                  dispatch(setSubCategory(categoryOption.subCategory));
                  dispatch(setCategoryId(categoryOption.categoryId));
                }
              
            });
        
          }
          if(areaId && allCities && allCities.length > 0){
            const areaIdInt = parseInt(areaId);
            const city = allCities.find(city => city.id === areaIdInt);
            if(city){
              dispatch(setArea(city));
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching request:', error);
        });
    }
  }, [currentRequest.requestId, allCities, dispatch]);

  useEffect(() => {
    if (currentRequest && currentRequest.content && currentRequest.content !== "") {
      setEditable(false);
    } else {
      setEditable(true);
    }
  }, []);

  const handleAreaChange = (selectedOption) => {
    //add the area id to the url
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('areaId', selectedOption.value.id);

    // Update the URL in the browser
  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.pushState(null, '', newUrl);
    
    dispatch(setArea(selectedOption.value));
    dispatch(setAreaId(selectedOption.value.id)); 
  };

  const areaOptions = [    
    ...allCities.map((city) => ({ value: city, label: city.cityName })),
  ];
  let parsed;
  let selectedArea;
  if (area && typeof area === 'string') {
    parsed =  JSON.parse(area);
  } else {
    parsed = area;      
  }
  if(parsed){
    selectedArea = areaOptions.find(option => {    
      return option.value.id === parsed.id;
    });
  }
  console.log("selectedArea", selectedArea);

  const navigate = useNavigate();
  
  const saveContent = () => {
    const authToken = localStorage.getItem("authToken");
    let requestCopy = {
      requestId: currentRequest.requestId,
      userId: user.userId,
      name: "",
      subject: currentRequest.subject,
      content: currentRequest.content,
      fileNames: currentRequest.fileNames,
      area: currentRequest.area,
      areaId: currentRequest.areaId,
      created: currentRequest.created,
      
      category: currentRequest.category,
      subCategory: currentRequest.subCategory,
      categoryId: currentRequest.categoryId,
      sent: currentRequest.sent,
      phone: currentRequest.phone
    };

  
    saveRequest(authToken, requestCopy).then(([res, status]) => {
      if(status === 401){
        console.log("Unauthorized access");
        navigate("/login")
      }
      if(res && res.data.requestId){
        setEditable(false);
        setCurrentRequest(res)
      }
    });
  }

  return (
    <div className="bg-white p-4 h-full" style={{ wordWrap: "break-word" }}>
      <h2
        dir="rtl"
        className="font-bold text-xl"
        style={{ textAlign: "right", marginBottom: "8px" }}
      >
        <InfoIcon style={{ marginLeft: "8px" }} />
        {projectDetails.title}
      </h2>
      <hr />
      <List>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ListItemText
            primary={projectDetails.name}
            sx={{ textAlign: "right", wordWrap: "break-word" }}
          />
          <Avatar sx={{ marginLeft: 2 }}>
            <PersonIcon />
          </Avatar>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ListItemText
            primary={projectDetails.email}
            sx={{ textAlign: "right", wordWrap: "break-word" }}
          />
          <Avatar sx={{ marginLeft: 2 }}>
            <EmailIcon />
          </Avatar>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ListItemText
            primary={
              <>
                {projectDetails.subCategory}
                <span style={{ color: "gray" }}>
                  {" "}
                  - {projectDetails.category}
                </span>
              </>
            }
            sx={{ textAlign: "right" }}
          />
          <Avatar sx={{ marginLeft: 2 }}>
            <WorkIcon />
          </Avatar>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Select
            value={selectedArea}
            onChange={handleAreaChange}
            options={areaOptions}
            className="w-48"
            placeholder="בחר אזור"
       
            styles={{
              control: (base) => ({
                ...base,
                borderRadius: "20px",
              }),
              singleValue: (base) => ({
                ...base,
                textAlign: "right",
              }),
              option: (base) => ({
                ...base,
                textAlign: "right",
              }),
            }}
          />
          <Avatar sx={{ marginLeft: 2 }}>
            <PlaceIcon />
          </Avatar>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <style>
            {`
      .phone-input input::placeholder {
        color: #FFCCCC !important;
      }
    `}
          </style>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <>
              <PhoneInput
                country={"il"}
                onlyCountries={["il"]}
                placeholder="הזן מספר טלפון"
                value={currentRequest.phone}
                onChange={(phoneNumber) => {
                  if (phoneNumber) {
                    dispatch(setPhone(phoneNumber));
                  }
                }}
                disableDropdown={true}
                disableCountryCode={true}
                autoFocus
                containerClass="phone-input"
                inputStyle={{ textAlign: "right" }}
              />
            </>
          </div>
          <Avatar sx={{ marginLeft: 2 }}>
            <PhoneIcon />
          </Avatar>
        </ListItem>
      </List>
      <hr />
      <div dir="rtl">
        <h2
          style={{
            textAlign: "right",
            marginTop: "20px",
            whiteSpace: "normal",
          }}
          className="font-bold text-xl"
        >
          {projectDetails.additionalInfoTitle}
        </h2>
        {!editable && (
          <p
            style={{
              textAlign: "right",
              marginTop: "20px",
              whiteSpace: "normal",
            }}
          >
            {projectDetails.additionalInfo}
          </p>
        )}
        {!editable && (
          <button
            onClick={() => setEditable(true)}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            ערוך
          </button>
        )}
        {editable && (
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextAreaRequired
                onChange={(e) => {
                  if (e.target.value) {
                    dispatch(setContent(e.target.value));
                  }
                }}
ג                content={projectDetails.additionalInfo}
              />
              <button
                onClick={(event) => {
                  event.preventDefault();
                  saveContent();
                }}
                disabled={!projectDetails.additionalInfo.trim()}
                className={`mt-4 font-bold py-2 px-4 rounded ${
                  !projectDetails.additionalInfo.trim()
                    ? "bg-gray-500"
                    : "bg-blue-500 hover:bg-blue-700"
                } text-white`}
              >
                שמור פרוייקט
              </button>
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};

export default ProjectSummary;
