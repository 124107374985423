import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { StarRating } from "baseui/rating";

const ProviderCard = ({ imageUrl, title, subtitle, description, rating }) => {
  const MAX_RATING = 5;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;
  const emptyStars = MAX_RATING - fullStars - (hasHalfStar ? 1 : 0);
};

const ProfessionalList = () => {
  
};

export default ProfessionalList;
