import React, { useEffect } from "react";
import MessagesUsers from "./MessagesUsers";
import MessagesProviders from "./MessagesProviders";
import { useSelector } from "react-redux";
import { setHasUnreadMessages } from "../redux_slices/userSlice";


function Messages({ isProvider }) {
  const user = useSelector((state) => state.user);


  useEffect(() => {
    if(user && user.hasUnreadMessage){
      setHasUnreadMessages(false);
    }
  }, [user]);

  
  // if (isProvider) {
  //   return <MessagesProviders />;
  // } else {
    return <MessagesUsers />;
  // }
}

export default Messages;
