import Select from "react-select";
import BottomFlowSection from "./BottomFlowSection";
import { useDispatch, useSelector } from "react-redux";
import { setArea } from "../redux_slices/currentRequestSlice";
import { useEffect, useState } from "react";
import { API_HOST } from "../config/config";

const FlowArea4 = ({ step }) => {
  const [allCities, setAllCities] = useState([]);
  const dispatch = useDispatch();
  const area = useSelector((state) => state.currentRequest.area);

  const handleAreaChange = (selectedOption) => {
    console.log("selected "+selectedOption);
    dispatch(setArea(selectedOption.value));
  };

  useEffect(() => {    
    const cities = sessionStorage.getItem("cities");
    if(cities && cities.length > 0){
      setAllCities(JSON.parse(cities));
    }
    else{
      const fetchCities = async () => {
        try {
          const response = await fetch(`${API_HOST}/cities`);
          if (response.ok) {
            const data = await response.json();
            if (data.data && Array.isArray(data.data)) {
              setAllCities(data.data);
              sessionStorage.setItem("cities", JSON.stringify(data.data));
            }
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };
  
      fetchCities();
      
    }
  }, []);

  
  const areaOptions = [
    
    ...allCities.map((city) => ({ value: city, label: city.cityName })),
  ];
  const selectedArea = areaOptions.find(option => JSON.stringify(option.value) === JSON.stringify(area));


  return (
    <div dir="rtl" className="max-w-2xl mx-auto mt-16 px-4 flex flex-col">
      <h2 className="text-right text-2xl font-bold mx-4">אזור בארץ לפרוייקט</h2>
      <Select
        value={selectedArea}
        onChange={handleAreaChange}
        options={areaOptions}
        className="w-full mt-4"
        placeholder="בחר אזור"
      />
      <BottomFlowSection step={step} isNextDisabled={!selectedArea}/>
    </div>
  );
};

export default FlowArea4;
