// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import selectedRecordsReducer from './selectedRecordsSlice';
import providerReducer from './providerSlice';
import flowReducer from './flowSlice'; // Import the flowSlice reducer
import currentRequestReducer from './currentRequestSlice';


export default configureStore({
    reducer: {
        user: userReducer,
        selectedRecordIds: selectedRecordsReducer,
        provider: providerReducer,
        flow: flowReducer, // Add it to the reducer object
        currentRequest: currentRequestReducer,

    },
});