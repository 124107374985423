import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import React, { useState } from "react";

export default function LoginSignup({ handleClose, winType = "login" }) {
  const [formType, setFormType] = useState(winType);

  const toggleForm = () => {
    setFormType((prev) => (prev === "login" ? "signup" : "login"));
  };

  return (
    <div className="mx-auto max-w-md">
      <div className="close text-black" style={{ textAlign: "right" }}>
        <button onClick={handleClose}>
          <span>×</span>
        </button>
      </div>

      {formType === "login" && (
        <LoginForm onToggle={toggleForm} handleClose={() => handleClose()} />
      )}

      {formType === "signup" && <SignupForm onToggle={toggleForm} />}
    </div>
  );
}
