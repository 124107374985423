import React from "react";
import { useDispatch } from "react-redux";
import { advanceFlow } from "../redux_slices/flowSlice"; // Import the action that advances the step

const BottomRequestQuote = ({ step, phone, areaCode, tos }) => {
  const dispatch = useDispatch(); // Get the dispatch function

  const getQuotesClicked = () => {
    console.log("BottomRequestQuote: getQuotesClicked");
    if (!tos) {
      alert("אנא אשר את תנאי השימוש ומדיניות הפרטיות");
      return;
    }
    dispatch(advanceFlow(step + 1));
  };

  const isNextDisabled = !phone || !areaCode;

  return (
    <div
      dir="rtl"
      className="fixed bottom-0 left-0 right-0 flex justify-center bg-gray-200 p-4"
    >
      <button
        style={{
          backgroundColor: isNextDisabled ? "gray" : "#00A67D",
          color: "white",
        }}
        className="mx-2 p-2 rounded w-1/6 h-10"
        onClick={getQuotesClicked} // Dispatch the action when the button is clicked
        disabled={isNextDisabled}
      >
        בקש הצעות מחיר
      </button>
    </div>
  );
};

export default BottomRequestQuote;
