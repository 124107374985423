import BottomRequestQuote from "./BottomRequestQuote";
import { useDispatch, useSelector } from "react-redux";
import {
    setArea,
  setAreaCode,
  setCommercialEmails,
  setContent,
  setEmail,
  setName,
  setPhone,
  setTOS,
} from "../redux_slices/currentRequestSlice";
import { useEffect, useState } from "react";
import { API_HOST } from "../config/config";

const FlowTestShortcut = () => {
  const dispatch = useDispatch();
  const [allCities, setAllCities] = useState([]);

  const areaCode = useSelector((state) => state.currentRequest.areaCode);
  const phone = useSelector((state) => state.currentRequest.phone);
  const tos = useSelector((state) => state.currentRequest.tos);
  const currentRequest = useSelector((state) => state.currentRequest);

  const handleAreaCodeChange = (event) => {
    dispatch(setAreaCode(event.target.value));
  };

  const handlePhoneNumberChange = (event) => {
    dispatch(setPhone(event.target.value));
  };

  const handleTosChange = (event) => {
    dispatch(setTOS(event.target.checked)); // Dispatch the action to the Redux store

  };

  const handleCommercialEmailsChange = (event) => {
    setCommercialEmails(event.target.checked);
  };

  const areaCodes = [
    "050",
    "052",
    "053",
    "054",
    "055",
    "056",
    "057",
    "058",
    "059",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
  ]; // replace with your actual area codes

  // Effect for fetching and setting cities
  useEffect(() => {
    const cities = sessionStorage.getItem("cities");
    if (cities && cities.length > 0) {
      setAllCities(JSON.parse(cities));
    } else {
      const fetchCities = async () => {
        try {
          const response = await fetch(`${API_HOST}/cities`);
          if (response.ok) {
            const data = await response.json();
            if (data.data && Array.isArray(data.data)) {
              setAllCities(data.data);
              sessionStorage.setItem("cities", JSON.stringify(data.data));
            }
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };

      fetchCities();
    }
  }, []);

  // Effect for handling currentRequest state
  useEffect(() => {
    if (allCities.length >0 && !currentRequest.area) {
      if (!areaCode) dispatch(setAreaCode("050"));
      if (!currentRequest.content) dispatch(setContent("This is my content: content_" + Math.floor(Math.random() * 1000)));
      if (!currentRequest.phone) dispatch(setPhone("1234567"));
      if (!currentRequest.name) dispatch(setName("customer_" + Math.floor(Math.random() * 1000)));
      if (!currentRequest.tos) dispatch(setTOS(true));
      if (!currentRequest.commericalEmails) dispatch(setCommercialEmails(true));
      if (!currentRequest.email) dispatch(setEmail("customer_" + Math.floor(Math.random() * 1000) + "@example.com"));
      if (!currentRequest.area) {
        const fakeArea = allCities[Math.floor(Math.random() * allCities.length)];
        dispatch(setArea(fakeArea));
      }
    }
  }, [currentRequest, areaCode, allCities, dispatch]);

  return (
    <div className="max-w-2xl mx-auto mt-16 px-4 flex flex-col">
      <h2 className="text-center text-2xl font-bold mx-4">
        הזן את מספר הטלפון שלך
      </h2>
      <div className="flex flex-col">
        <div className="flex justify-center mt-4">
          <input
            type="text"
            value={phone}
            onChange={handlePhoneNumberChange}
            placeholder="מספר טלפון"
            className="w-1/3 border border-gray-200 border-2 rounded-lg order-2 ml-4 text-gray-700 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
            maxLength={7}
          />
          <select
            value={areaCode}
            onChange={handleAreaCodeChange}
            className="w-1/6 border border-gray-200 border-2 rounded-lg order-1 ml-4 text-gray-700 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="" disabled>
              קידומת
            </option>
            {areaCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
        </div>
        <div dir="rtl" className="text-center mt-4">
          <div>
            <input
              type="checkbox"
              id="terms"
              name="terms"
              className="ml-2"
              onChange={handleTosChange} // Add this line
            />
            <label for="terms">
              אני מסכים{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                למדיניות הפרטיות
              </a>
              {" ו-"}
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                לתנאי השירות
              </a>
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="commercialEmails"
              name="commercialEmails"
              className="ml-2"
              onChange={handleCommercialEmailsChange} // Add this line
            />
            <label for="commercialEmails">אני מסכים לקבל מיילים מסחריים</label>
          </div>
        </div>
      </div>
      <BottomRequestQuote
        step={5}
        phone={phone}
        areaCode={areaCode}
        tos={tos}
      />
    </div>
  );
};

export default FlowTestShortcut;
