import React, { useState, useEffect } from "react";
import { API_HOST } from "../config/config.js";
import { useSelector } from "react-redux";

const UseExistingRequest = ({ handleFinished, recordIdContext }) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const selectedRecordIds = useSelector((state) => state.selectedRecordIds);

  const handleSubmit = (event) => {
    event.preventDefault(); // Add this line to prevent the default form submission event
    setIsLoading(true);

    const requestData = {
      userId: user.userId,
      request: {
        requestId: selectedRequest.requestId,
        userId: user.userId,
        name: selectedRequest.name,
        subject: subject,
        content: content,
        fileNames: selectedRequest.fileNames,
      },
      selectedProviderIds: recordIdContext
        ? [recordIdContext]
        : selectedRecordIds,
    };

    fetch(`${API_HOST}/requests/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.ok) {
          handleFinished(true);        
        } else {
          handleFinished(false);

          alert("היתה תקלה בזמן שליחת ההודעה - נא נסה שנית מאוחר יותר");

          throw new Error("Network response was not ok");
        }
      })
     
      .catch((error) => {
        console.error("Error:", error);
        handleFinished(false);
      });

  };

  useEffect(() => {
    fetch(`${API_HOST}/requests?userId=${user.userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          setRequests(data.data);
        } else {
          console.error("Data is not an array:", data.data);
        }
      });
  }, [user.userId]);

  useEffect(() => {
    if (selectedRequest) {
      setSubject(selectedRequest.subject);
      setContent(selectedRequest.content);
      // Assuming files are part of the request object
      // setFiles(selectedRequest.files);
    }
  }, [selectedRequest]);

  const handleSelectChange = (event) => {
    const requestName = event.target.value;
    const request = requests.find((req) => req.name === requestName);
    setSelectedRequest(request);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader w-16 h-16 border-8 border-t-8 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className={`mx-auto border-2 border-gray-300 rounded-lg p-6 w-full`}>
      <div className="close" style={{ textAlign: "left" }}>
        <button onClick={()=>handleFinished(false)}>
          <span>×</span>
        </button>
      </div>

      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        dir="rtl"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            בחר בקשה:
            <select onChange={handleSelectChange}>
              {requests.map((request, index) => (
                <option key={index} value={request.id}>
                  {request.name}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            נושא:
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </label>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            תוכן:
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="form-input mt-1 block w-full shadow appearance-none border rounded w-fulls py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="13"
            />
          </label>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            קבצים:
          </label>
          {selectedRequest &&
            selectedRequest.fileNames &&
            selectedRequest.fileNames.split(",").map((file, index) => (
              <div key={index} className="flex items-center justify-between">
                <span>{file}</span>
              </div>
            ))}
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            שלח
          </button>
        </div>
      </form>
    </div>
  );
};

export default UseExistingRequest;
