// JSX
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { Navbar, Nav, NavDropdown, Form, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logUserViaAuth } from "../login/LogUserInViaAuth";
import { fetchCategories } from "../fetch/CategoriesFetch";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCategory,
  setCategoryId,
  setSubCategory,
} from "../redux_slices/currentRequestSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { setUser } from "../redux_slices/userSlice";

function Header2() {
  const user = useSelector((state) => state.user);
  const [categories, setCategories] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);  

    const categoryIdFromUrl = queryParams.get("category");
    fetchCategories().then((fetched) => {
      setCategories(fetched);
      if (categoryIdFromUrl) {
        const categoryOpt = fetched.find(
          (category) => category.id === parseInt(categoryIdFromUrl)
        );

        if (categoryOpt) {
          const categoryOption = {
            category: categoryOpt.category,
            subCategory: categoryOpt.subCategory,
            label: categoryOpt.subCategory,
            categoryId: categoryOpt.id,
          };
          dispatch(setCategory(categoryOption.category));
          dispatch(setSubCategory(categoryOption.subCategory));
          dispatch(setCategoryId(categoryOption.categoryId));

          // Set the search value with the category that you found
          setSearchValue(categoryOption);
        }
      }
    });
  }, [dispatch]);

  if (!user && token) {
    logUserViaAuth(token);
  }

  const signout = () => {
    localStorage.removeItem("authToken");
    dispatch(setUser(null));
    navigate("/login");
  };

  return (
    <Navbar className="h-16" style={{ backgroundColor: "#F0F8FF" }} expand="lg">
      <Container fluid>
        <Navbar.Brand
          href="/"
          className="font-pacifico font-bold text-gray-800 flex items-center"
        >
          <span className="text-red-500">B</span>
          <span className="text-yellow-500">i</span>
          <span className="text-green-500">d</span>
          <span className="text-blue-500">m</span>
          <span className="text-indigo-500">e</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="d-flex justify-content-between"
        >
          <div></div>
          <Form className="d-flex justify-content-center" style={{ flex: 1 }}>
            <div style={{ minWidth: "400px", marginRight: "10px" }}>
              <Select
                options={categories.map((categoryOption) => ({
                  category: categoryOption.category,
                  subCategory: categoryOption.subCategory,
                  label: categoryOption.subCategory,
                  categoryId: categoryOption.id, // Change this to category.id
                }))}
                placeholder="חפש בעל מקצוע"
                value={searchValue}
                onChange={(selectedOption) => {
                  //set the selected category and subCategory in the redux store
                  console.log(selectedOption);

                  if (searchValue !== selectedOption) {
                    setSearchValue(selectedOption);
                    dispatch(setCategory(selectedOption.category));
                    dispatch(setSubCategory(selectedOption.subCategory));
                    dispatch(setCategoryId(selectedOption.categoryId));
                    if (user) {
                      const queryParams = new URLSearchParams(window.location.search);  
                      const categoryIdFromUrl = queryParams.get("category");
                      queryParams.set('category', selectedOption.categoryId);
                      const params = queryParams.toString();
                      navigate(
                        `/pros_list?${params}`);
                      
                    } else {
                      navigate(`/flow?category=${selectedOption.categoryId}`);
                    }
                  }
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: "20px",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    textAlign: "right",
                  }),
                  option: (base) => ({
                    ...base,
                    textAlign: "right",
                  }),
                }}
              />
            </div>
          </Form>
          {user && (
            <Nav dir="rtl" className="mb-2 mb-lg-0 d-flex align-items-center">
              <Nav.Item className="mr-4 h5 ">שלום {user.firstName}</Nav.Item>
              <NavDropdown
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {user && user.avatar ? (
                      <img
                        src={user.avatar}
                        alt="User Avatar"
                        style={{ width: 35, height: 35, borderRadius: "50%" }}
                      />
                    ) : (
                      <AccountCircleIcon style={{ fontSize: 35 }} />
                    )}
                  </div>
                }
                id="nav-dropdown"
                className="mt-4 mr-2 custom-nav-dropdown dropdown dropdown-menu-left"
              >
                <NavDropdown.Item href="#action">
                  <div className="text-right">הגדרות</div>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/projects")}>
                  <div className="text-right">הפרוייקטים שלי</div>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={signout}>
                  <div className="text-right">התנתק</div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header2;
