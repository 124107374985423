import { TextField } from "@mui/material";
import React, { useState } from "react";

function TextAreaRequired({ onChange, content, rows = 16}) {
  const [text, setText] = useState(content);

  const handleChange = (event) => {
    setText(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const style = text
    ? { border: "1px solid gray", backgroundColor: "white" }
    : { border: "1px solid red", backgroundColor: "#FCF4F2" };

  return (
    <textarea
      className="w-full"
      style={style}
      value={text}
      rows={rows}
      onChange={handleChange}
    />
  );
}

export function TextFieldRequired({ onChange}) {
  const [text, setText] = useState("");

  const handleChange = (event) => {
    setText(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const style = text
    ? { border: "1px solid gray", backgroundColor: "white" }
    : { border: "1px solid red", backgroundColor: "#FCF4F2" };

  return (
    <TextField
      className="w-full"
      style={style}
      value={text}
      onChange={handleChange}
    />
  );
}

export default TextAreaRequired;
