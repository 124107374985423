import React, { useEffect, useState } from "react";
import { loginUser } from "../redux_slices/userActions";
import { Link } from "react-router-dom";
import { API_HOST } from "../config/config";
import StatusCode from "../utility/StatusCode";

export default function SignUpPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.initialize({
          client_id:
            "928594069070-4cf7qmsujlvaeu78s3f3juvfl6rn1frg.apps.googleusercontent.com",
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("googleButton"),
          { theme: "outline", size: "large" } // customization attributes
        );
        window.google.accounts.id.prompt(); // also display the One Tap dialog

        // Clear the interval once the Google One Tap library is loaded
        clearInterval(intervalId);
      }
    }, 100); // Check every 100ms

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  function handleCredentialResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
    // Parse the ID token
    const payload = JSON.parse(atob(response.credential.split(".")[1]));

    // Log the user's information
    console.log("User's name: " + payload.name);
    console.log("User's email: " + payload.email);
    console.log("User's profile picture URL: " + payload.picture);
    console.log("User's payload: " + payload);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!firstName || !email || !password || !confirmPassword) {
      setErrorMessage("אנא מלא את כל השדות");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("פורמט האימייל אינו תקני");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("הסיסמאות אינן תואמות");
      return;
    }

    try {
      const response = await fetch(`${API_HOST}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
        }),
      });

      const data = await response.json();

      if (response.status === StatusCode.CREATED) {
        // Signup successful, handle the response data
        console.log("Signup successful:", data);
        // Perform any necessary actions (e.g., redirect, show success message, etc.)
      } else {
        // Signup failed, handle the error
        console.log("Status Code:", response.status);

        if (data.hasErrors) {
          setErrorMessage(data.errors.message);
        } else {
          setErrorMessage("Signup failed. Please try again.");
        }
      }
    } catch (error) {
      // Handle network or other errors
      console.log("Error:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <section className="vh-100" dir="rtl">
      <div className="container py-5 h-100">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
              className="img-fluid"
              alt="Phone image"
            />
          </div>
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
            <form onSubmit={handleSubmit}>
              <div data-mdb-input-init className="form-outline mb-4">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div
                      data-mdb-input-init
                      className="form-outline mb-4"
                      style={{ flex: 1}}
                    >
                      <input
                        type="text"
                        id="form1Example0"
                        className="form-control form-control-lg"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label className="form-label" htmlFor="form1Example0">
                        שם
                      </label>
                    </div>

                    <div
                      data-mdb-input-init
                      className="form-outline mb-4"
                      style={{ flex: 1 }}
                    >
                      <input
                        type="text"
                        id="form1Example1"
                        className="form-control form-control-lg"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label className="form-label" htmlFor="form1Example1">
                        שם משפחה
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div data-mdb-input-init className="form-outline mb-4" style={{ marginTop: "-30px" }}>
                <input
                  type="email"
                  id="form1Example13"
                  className="form-control form-control-lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="form-label" htmlFor="form1Example13">
                  אימייל
                </label>
              </div>

              <div data-mdb-input-init className="form-outline mb-4">
                <input
                  type="password"
                  id="form1Example23"
                  className="form-control form-control-lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label className="form-label" htmlFor="form1Example23">
                  סיסמה
                </label>
              </div>

              <div data-mdb-input-init className="form-outline mb-4">
                <input
                  type="password"
                  id="form1Example33"
                  className="form-control form-control-lg"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <label className="form-label" htmlFor="form1Example33">
                  אימות סיסמה
                </label>
              </div>
              <div
                style={{
                  marginTop: "-20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input type="checkbox" id="terms" name="terms" required />
                <label htmlFor="terms" style={{ marginRight: "10px" }}>
                  אני מסכים ל<a href="/privacy-policy">מדיניות פרטיות</a> ו
                  <a href="/terms-of-service">תנאי שימוש</a>
                </label>
              </div>
              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input type="checkbox" id="marketing" name="marketing" />
                <label htmlFor="marketing" style={{ marginRight: "10px" }}>
                  אני מסכים
                  <Link to="/new-page">לקבל דיוור פרסומי ושיווקי</Link>
                </label>  
              </div>

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

              <button
                type="submit"
                data-mdb-button-init
                data-mdb-ripple-init
                className="btn btn-primary btn-lg btn-block"
              >
                הרשם
              </button>

              <Link to="/login" className="d-block text-center mt-3 small">
                כבר נרשמת ? עבור לדף הכניסה
              </Link>
              <div style={{ textAlign: "center" }}>או</div>
              <div id="googleButton" className="w-full"></div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
