import React, { useState } from "react";
import { API_HOST } from "../config/config";
import StatusCode from "../utility/StatusCode.js";

const SignupForm = ({ onToggle }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!privacyAgreement || !termsOfService) {
      setError("Please agree to the privacy policy and terms of service.");
      return;
    }

    try {
      const response = await fetch(`${API_HOST}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
        }),
      });

      const data = await response.json();

      if (response.status === StatusCode.CREATED) {
        // Signup successful, handle the response data
        console.log("Signup successful:", data);
        onToggle();
        // Perform any necessary actions (e.g., redirect, show success message, etc.)
      } else {
        // Signup failed, handle the error
        console.log("Status Code:", response.status);

        if (data.hasErrors) {
          setError(data.errors.message);
        } else {
          setError("Signup failed. Please try again.");
        }
      }
    } catch (error) {
      // Handle network or other errors
      console.log("Error:", error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="mx-auto max-w-lg">
      <h2 className="text-lg font-medium mb-6">Signup</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="block mb-2">
            First Name:
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block mb-2">
            Last Name:
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-2">
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>
        <div>
          <label htmlFor="phoneNumber" className="block mb-2">
            Phone Number:
          </label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-2">
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>
        <div>
          <label htmlFor="confirmPassword" className="block mb-2">
            Confirm Password:
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>
        <div className="col-span-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={privacyAgreement}
              onChange={(e) => setPrivacyAgreement(e.target.checked)}
              className="mr-2"
            />
            <span>I agree to the privacy policy</span>
          </label>
        </div>
        <div className="col-span-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={termsOfService}
              onChange={(e) => setTermsOfService(e.target.checked)}
              className="mr-2"
            />
            <span>I agree to the terms of service</span>
          </label>
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white"
          >
            Sign Up
          </button>
        </div>
      </form>
      <div className="mt-4 text-center">
        <button
          type="button"
          className="text-blue-500 hover:underline"
          onClick={onToggle}
        >
          Already have an account? Login
        </button>
      </div>
    </div>
  );
};

export default SignupForm;
