import React from "react";
import { useSelector } from "react-redux";
import FlowDescription1 from "./FlowDescription1";
import FlowName2 from "./FlowName2";
import FlowEmail3 from "./FlowEmail3.jsx";
import FlowArea4 from "./FlowArea4.jsx";
import FlowPhone5 from "./FlowPhone5.jsx";
import FlowLoading6 from "./FlowLoading.jsx";
import { useNavigate } from "react-router-dom";

const Flow = () => {
  console.log("Flow: rendering");
  const step = useSelector((state) => state.flow.step);
  const requestId = useSelector((state) => state.currentRequest.requestId);

  const navigate = useNavigate();

  console.log("bla bla bla");
  switch (step) {
    case 1:
      return <FlowDescription1 step={1}/>;
    case 2:
      return <FlowEmail3 step={2}/>;
    case 3:
      return <FlowName2 step={3}/>;
    case 4:
      return <FlowArea4 step={4}/>;
    case 5:
      return <FlowPhone5 step={5}/>;
    case 6:
      return <FlowLoading6 step={6}/>;
    case 7:
      navigate(`/pros_list?requestId=`+requestId);
      return;
    default:
      return <div>Invalid step</div>;
  }
};

export default Flow;
