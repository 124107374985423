import { useEffect } from "react";
import { useSelector } from "react-redux"; // Import useSelector
import { useDispatch } from "react-redux";
import { setHasChatAccount, setUser } from "../redux_slices/userSlice";
import { API_HOST } from "../config/config";
import { ChatEngine } from "react-chat-engine";
import "./Messages.css";
import { fetchUser } from "../redux_slices/userActions";

function MessagesUsers() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user); // Retrieve the user from the Redux store

  useEffect(() => {
    if (user && !user.hasChatAccount) {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {

        // Send request to backend to create a chat account
        // Replace with your actual API call
        fetch(`${API_HOST}/chat/createChatAccount`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: user.userId,
          }),
        }).then((response) => {
          if (response.ok) {
            // Update the user in the Redux store
            dispatch(setHasChatAccount(true));
          }
        });
      }
    }
    const authToken = localStorage.getItem("authToken");

    if (!user && authToken) {
      // Check for an existing auth token
      const fetchUserData = async () => {
        const data = await fetchUser(authToken);
        dispatch(setUser(data.data.user));
      };

      fetchUserData();
    }
  }, [user, dispatch]); // Run the effect when `user` changes

  return (
    user && (
      <ChatEngine
        projectID="251fedc0-15c7-42d8-b421-1503ad33499e"
        userName={user.email}
        userSecret={user.userId}
        hideNewChatButton={true}
        height="80vh"
        renderNewChatForm={(creds) => {}}
        renderPeopleSettings={(creds, chat) => {}}
        renderOptionsSettings={(creds, chat) => {}}
      />
    )
  );
}

export default MessagesUsers;
