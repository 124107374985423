import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  requestId: null,
  name: '',
  category: '',
  subCategory: '',
  categoryId: '',
  content: '',
  email:'',
  phone: null,
  areaCode: '',
  area: null,
  tos: false,
  commercialEmails: false,
  fileNames: null,
}

const currentRequestSlice = createSlice({
  name: 'currentRequest',
  initialState,
  reducers: {
    setRequestId: (state, action) => {
      state.requestId = action.payload;
    },    
    setName: (state, action) => {
      state.name = action.payload;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setCurrentRequest: (state, action) => {
      return action.payload;
    },
    //use this instead of setCurrentRequest - later 
    // setCurrentRequest: (state, action) => {
    //   Object.assign(state, action.payload);
    // },
    
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setAreaCode: (state, action) => {
      state.areaCode = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setAreaId: (state, action) => {
      state.areaId = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategory = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setTOS: (state, action) => {
      state.tos = action.payload;
    },
    setCommercialEmails: (state, action) => {
      state.commercialEmails = action.payload;
    },
  },
})

export const { setCategory, setSubCategory, setCategoryId, setCurrentRequest, setTOS, setCommercialEmails, setAreaCode, setPhone, setRequestId, setName, setContent, setEmail,setArea,setAreaId } = currentRequestSlice.actions

export default currentRequestSlice.reducer