import {React, useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage/MainPage';
import Messages from './messages/Messages';
import { useDispatch, useSelector } from 'react-redux';
import ProviderDetails from './Record/ProviderDetails';
import { fetchRecheckUnreadMessages, fetchUser } from './redux_slices/userActions';
import { setHasUnreadMessages, setUser } from './redux_slices/userSlice';
import Flow from './flow/Flow';
import ProsListWrraper from './ProsList/ProsListWrraper';
import Header2 from './header/Header2';
import YouTube from './projects/ProjectWrrapper';
import LoginPage from './login/LoginPage';
import SignUpPage from './login/SignupPage';
import FlowTest from './flow/FlowTest';

function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useSelector(state => state.user); // Retrieve the user from the Redux store
  const dispatch = useDispatch();

  

  useEffect(() => {
    const handleLogout = () => {
      setIsLoggedIn(false);
    };
  
    const handleLogin = () => {
      if(user){
        setIsLoggedIn(true);
      }
      else{
        setIsLoggedIn(false);
      }      
    };

    if(user && !isLoggedIn){
      handleLogin(user);
      return;
    }
    const authToken = localStorage.getItem('authToken');
    if(!user && authToken){
        // Check for an existing auth token
      const fetchUserData = async () => {
      
        const data =  await fetchUser(authToken);     
        if (data && data.data && data.data.user) {
  
          dispatch(setUser(data.data.user));
          if(!data.data.user.hasUnreadMessage){
            fetchRecheckUnreadMessages(authToken).then(
              (hasUnreadMessage) => {
                dispatch(setHasUnreadMessages(hasUnreadMessage));
              }
            ).catch(error => {
              console.error('Error fetching additional data:', error);
            });
          }
        }
        
      }
      fetchUserData();

    }
    else if(!user){
      handleLogout();
    }
  }, [dispatch,user,isLoggedIn]);


  return (
    <Router>
      {/* <Header isLoggedIn={isLoggedIn} userName={userName} /> */}
      <Header2 />
      <Routes>
        <Route path="/" element={<MainPage isLoggedIn={isLoggedIn}/>} />
        <Route path="/flow" element={<Flow />} />
        <Route path="/pros_list" element={<ProsListWrraper />} />  
        <Route path="/projects" element={<YouTube/>} />  {/*temp to delete */}
        <Route path="/login" element={<LoginPage/>} />  
        <Route path="/signup" element={<SignUpPage/>} />  
        <Route path="/messages" element={<Messages isProvider={user ? user.isProvider : false}/>} />
        <Route path="/providers/:providerId" element={<ProviderDetails />} />
        <Route path="/flowTest" element={<FlowTest />} />
      </Routes>
    </Router>
  );
}

export default App;