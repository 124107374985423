import React, { useState } from "react";
import { API_HOST } from "../config/config.js";
import { useSelector } from "react-redux";

const CreateNewRequest = ({ handleFinished, recordIdContext }) => {
  const [requestName, setRequestName] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const selectedRecordIds = useSelector((state) => state.selectedRecordIds);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!requestName || !subject || !content) {
      alert(
        "All fields are required, please fill out all fields before submitting."
      );
      return;
    }

    let uploadedFileNames = [];

    for (const file of files) {
      const response = await fetch(`${API_HOST}/requests/get-signed-url`, {
        // replace with your actual endpoint
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileName: file.name,
          userId: user.userId,
        }),
      });

      const result = await response.json();
      const signedUrl = result.data;

      await fetch(signedUrl, {
        method: "PUT",
        body: file,
      });
      uploadedFileNames.push(file.name);
    }

    fetch(`${API_HOST}/requests`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestName,
        subject,
        content,
        userId: user.userId,
        files: uploadedFileNames,
        selectedProviderIds: recordIdContext
        ? [recordIdContext]
        : selectedRecordIds,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setRequestSent(true);
          setIsLoading(false);
          handleFinished(true);          
        } else {
          handleFinished(false);
          alert("היתה תקלה בזמן שליחת ההודעה - נא נסה שנית מאוחר יותר");
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        handleFinished(false);
      });

  };

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    if (newFiles.length + files.length > 3) {
      alert("You can only upload up to 3 files.");
      return;
    }
    for (let i = 0; i < newFiles.length; i++) {
      if (newFiles[i].size > 5000000) {
        // 5 MB in bytes
        alert("Each file must be no more than 5MB.");
        return;
      }
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader w-16 h-16 border-8 border-t-8 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className={`mx-auto border-2 border-gray-300 rounded-lg p-6 w-full md:w-full lg:w-full`}>
      <div className="close" style={{ textAlign: "left" }}>
        <button onClick={()=>handleFinished(false)}>
          <span>×</span>
        </button>
      </div>

      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        dir="rtl"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            שם הבקשה:
            <input
              type="text"
              value={requestName}
              onChange={(e) => setRequestName(e.target.value)}
              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.xlsx"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </label>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            נושא:
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </label>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            תוכן:
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="form-input mt-1 block w-full shadow appearance-none border rounded w-fulls py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="13"
            />
          </label>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            style={{ textAlign: "right" }}
          >
            העלה קבצים:
            <input
              type="file"
              multiple
              onChange={handleFileUpload}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </label>
          {files.map((file, index) => (
            <div key={index} className="flex items-center justify-between">
              <button
                onClick={() => handleRemoveFile(index)}
                style={{
                  background: "none",
                  color: "red",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                הסר
              </button>
              <span>{file.name}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            שלח
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewRequest;
