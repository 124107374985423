import { createSlice } from "@reduxjs/toolkit";

const selectedRecordsSlice = createSlice({
  name: "selectedRecords",
  initialState: [],
  reducers: {
    setSelectedRecords: (state, action) => {
      console.log("setSelectedRecords called with", action.payload);
      return action.payload;
    },
  },
});

export const { setSelectedRecords } = selectedRecordsSlice.actions;

export default selectedRecordsSlice.reducer;
