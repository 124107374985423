import BottomFlowSection from "./BottomFlowSection";
import { useDispatch, useSelector } from "react-redux";
import { setEmail } from "../redux_slices/currentRequestSlice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import isEmailExists from "../fetch/CheckEmailExists";

const FlowEmail3 = ({ step }) => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.currentRequest.email);
  const [validated, setValidated] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const validateEmail = (emailToValidate) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailToValidate).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const validEmail = validateEmail(event.target.value);
    if (validEmail) {
      checkEmailExists(event.target.value);       
    } 
    else{
      setEmailExists(false);
    }
    setValidated(validEmail);
    dispatch(setEmail(event.target.value));
  };

  const checkEmailExists = async (email) => {
     const exists = await isEmailExists(email);
     if(exists){
        setEmailExists(true);
     }
     else{
      setEmailExists(false);
     }
  };

  useEffect(() => {
    const validEmail = validateEmail(email);
    if (validEmail) {
      const exists = !checkEmailExists(email);
      if (exists) {
        setEmailExists(true);
        return;
      } else {
        setEmailExists(false);
      }
    } else {
      setEmailExists(false);
    }
    setValidated(validEmail);
    dispatch(setEmail(email));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only on initial render

  return (
    <div dir="rtl" className="max-w-2xl mx-auto mt-16 px-4 flex flex-col">
      <h2 className="text-right text-2xl font-bold mx-4">
        הזן את כתובת הדוא"ל המועדפת עליך
      </h2>
      <input
        type="email"
        maxLength={100}
        value={email}
        onChange={handleEmailChange}
        placeholder="דואר אלקטרוני עיקרי"
        className={`w-full mt-4 border-2 rounded-lg ${
          emailExists ? "border-red-500" : "border-gray-200"
        }`}
      />
      {emailExists && (
        <p className="text-red-500">
          האימייל שבחרת כבר קיים במערכת, בחר אימייל אחר או{" "}
          <Link to={`/login?email=${email}`} className="text-blue-500">
            לחץ כאן
          </Link>{" "}
          כדי להתחבר עם {email}
        </p>
      )}{" "}
      <BottomFlowSection step={step} isNextDisabled={!validated || emailExists} />
    </div>
  );
};

export default FlowEmail3;
