import axios from 'axios';
import { API_HOST } from '../config/config';

export const loginWithGoogle = async (payload) => {

    try {
        // Replace with your actual API endpoint
        const response = await axios.post(`${API_HOST}/login-with-google`, payload);

        return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
};
