import { API_HOST } from "../config/config";

async function isEmailExists(inputEmail) {
    try {
        const response = await fetch(`${API_HOST}/user/checkEmailExists`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: inputEmail })
        });
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export default isEmailExists;
