// Reviews.jsx
import React, { useEffect, useState } from "react";
import Stars from "./Stars";
import avatar from "../images/avatar.png";
import { API_HOST } from "../config/config";



export const SORT_OPTIONS = {
  MOST_RECENT: "האחרונים ביותר",
  MOST_USEFUL: "המועילים ביותר",
  HIGHEST_RATED: "הגבוהים ביותר",
  LOWEST_RATED: "הנמוכים ביותר",
};

const Reviews = ({ sortOption, providerId }) => {
  const [sortedReviews, setSortedReviews] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetch(`${API_HOST}/reviews?providerId=${providerId}`)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.data);
        setSortedReviews(data.data);
      })
      .catch((error) => console.error("Error:", error));
  }, [providerId]);

  useEffect(() => {
    if(reviews && reviews.length > 0){
        let sorted = [...reviews];
        switch (sortOption) {
        case SORT_OPTIONS.MOST_RECENT:
            sorted.sort((a, b) => new Date(b.date) - new Date(a.date));
            break;
        case SORT_OPTIONS.MOST_USEFUL:
            sorted.sort((a, b) => b.text.length - a.text.length);
            break;
        case SORT_OPTIONS.HIGHEST_RATED:
            sorted.sort((a, b) => b.stars - a.stars);
            break;
        case SORT_OPTIONS.LOWEST_RATED:
            sorted.sort((a, b) => a.stars - b.stars);
            break;
        default:
            break;
        }
        setSortedReviews(sorted);
    }
  }, [sortOption, reviews]);

  if(!sortedReviews || !sortedReviews.length){
    return (
        <div className="w-full text-center mt-10">
            <p className="text-2xl text-gray-600">אין ביקורות כרגע</p>
        </div>
    );
}

  return (
    <div className="mt-5">
      {sortedReviews.map((review, index) => (
        <div key={index} className="p-6 bg-white rounded-lg shadow-lg mt-4">
          <div className="flex flex-row-reverse items-center">
            <img
              src={avatar}
              alt={review.reviewer}
              className="w-10 h-10 rounded-full ml-2"
            />
            <div className="text-right">
              <h3 className="text-lg font-bold text-gray-700">
                {review.reviewer}
              </h3>
              <p className="text-sm text-gray-500">{new Date(review.date).toLocaleDateString('en-GB')}</p>
              <Stars score={review.stars} />
            </div>
          </div>
          <div dir="rtl">
            <h4 className="mt-2 text-lg text-gray-700">{review.title}</h4>
            <p className="mt-2 text-gray-700">{review.text}</p>
            <div className="mt-2">
              <span className="text-sm text-gray-500">
                {review.helpfulVotes} אנשים מצאו זאת מועילה
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reviews;
