import React, { useState } from "react";
import ShortMessage from "./ShortMessage";
import ProfessionalList from "./ProfessionalList";
import ProjectSummary from "./ProjectSummary";
import SideButtons from "./SideButtons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { API_HOST } from "../config/config";
import { useNavigate } from "react-router-dom";

const ProsListWrraper = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const currnetRequest = useSelector((state) => state.currentRequest);
  const selectedRecordIds = useSelector((state) => state.selectedRecordIds);

  const sendRequest = (recordIdContext) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      navigate("/login");
      return;
    }
    if(!currnetRequest.content || currnetRequest.content === ""){
      alert("אנא מלא את תיאור הבעיה");
      return;
    
    }
    setIsLoading(true);
    
    const requestData = {
      userId: user.userId,

      request: {
        requestId: currnetRequest.requestId,
        category: currnetRequest.category,
        subCategory: currnetRequest.subCategory,
        categoryId: currnetRequest.categoryId,
        userId: user.userId,
        name: currnetRequest.name,
        area: currnetRequest.area,
        content: currnetRequest.content,
        phone: currnetRequest.phone,
        fileNames: currnetRequest.fileNames,
      },
      selectedProviderIds: recordIdContext
        ? [recordIdContext]
        : selectedRecordIds,
    };

    fetch(`${API_HOST}/requests/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        setIsLoading(false);
        if (response.ok) {
          setIsSuccessModalOpen(true);
        } else {
          if (response.status === 401) {
            navigate("/login");
            return;
          }
          alert("היתה תקלה בזמן שליחת ההודעה - נא נסה שנית מאוחר יותר");

          throw new Error("Network response was not ok");
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader w-16 h-16 border-8 border-t-8 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (isSuccessModalOpen) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircleIcon
          style={{ color: "green", fontSize: 50, marginBottom: "10px" }}
        />
        <h2 style={{ color: "green", marginBottom: "30px" }}>
          ההודעה נשלחה בהצלחה
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          <button
            style={{
              backgroundColor: "blue",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px",
              marginBottom: "10px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              height: "40px",
              marginRight: "10px",
              width: "140px",
            }}
            onClick={() => setIsSuccessModalOpen(false)}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "darkblue")
            }
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "blue")}
          >
            סגור
          </button>
          <button
            style={{
              backgroundColor: "blue",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              height: "40px",
              width: "140px",
            }}
            onClick={() => navigate("/messages")}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "darkblue")
            }
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "blue")}
          >
            צפה בהודעות
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-gray-100 p-2 md:p-4 grid grid-cols-1 md:grid-cols-3 gap-4 min-h-screen">
      <div className="md:col-span-1 overflow-hidden">
        <ProjectSummary />
      </div>
      <div className="md:col-span-2 flex flex-col justify-between w-full">
        <SideButtons sendAction={sendRequest} />
        <div className="flex-grow w-full">
          <ShortMessage
            sendAction={sendRequest}
            userName={user ? user.firstName : ""}
          />
        </div>
        <div className="flex-grow w-full">
          <ProfessionalList />
        </div>
      </div>
    </div>
  );
};

export default ProsListWrraper;
