import { API_HOST } from '../config/config';
import setCookieToUpperLevel from '../cookies/CookiesSetter';

export const  fetchUser = async (authToken) => {

  try {
    const response = await fetch(`${API_HOST}/loginWithToken`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      const base64Token = btoa(data.data.token); // Encode the token in base64
      localStorage.setItem("authToken", base64Token);
      setCookieToUpperLevel(data.data.user.firstName, data.data.user.avatar);
      return data;
    }
    else if(response.status === 401){
      localStorage.removeItem('authToken');
    } 
  } catch (error) {
    console.error('Error fetching user:', error);
  }
  return null;
};

export const loginUser = async (email, password) => {
  const response = await fetch(`${API_HOST}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: email, password }),
  });

  const data = await response.json();

  if (response.ok) {
    const base64Token = btoa(data.data.token); // Encode the token in base64
    localStorage.setItem("authToken", base64Token);
    setCookieToUpperLevel(data.data.user.firstName, data.data.user.avatar);
  }
  return { result: data.data, statusCode: response.status };
  
};

export const fetchRecheckUnreadMessages = async (authToken) =>{

  const response = await fetch(`${API_HOST}/messages/hasUnreadMessages`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,

    }
  });
  const data = await response.json();
  if(response.ok && data.data){
    const hasUnreadMessage = data.data;
    return hasUnreadMessage;
    
  } 
  
}