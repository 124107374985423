import React, { useState, useEffect } from "react";
import Record from "../Record/Record.jsx";
import { API_HOST } from "../config/config.js";
import { setSelectedRecords } from "../redux_slices/selectedRecordsSlice.js";
import { useDispatch, useSelector } from "react-redux";
import RequestForm from "../requests/RequestForm.jsx";
import Modal from "../login/Modal.js";
import LoginSignup from "../login/LoginSignup.jsx";
import { setArea } from "../redux_slices/currentRequestSlice.js";

async function fetchRecords(city) {
  let response;
  if (city === "" || city === undefined) {
    response = await fetch(`${API_HOST}/providers`);
  } else {
    response = await fetch(
      `${API_HOST}/providers${city ? `?city=${city}` : ""}`
    );
  }
  return response.json();
}

function MainPage({ isLoggedIn, hasUnreadMsg }) {
  const [records, setRecords] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [showList, setShowList] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectedRecords = useSelector((state) => state.selectedRecordIds); // Moved to the top level

  const dispatch = useDispatch();
  

  useEffect(() => {
    const savedRecords = localStorage.getItem("records");
    if (savedRecords) {
      setRecords(JSON.parse(savedRecords));
    }
    const cities = sessionStorage.getItem("cities");
    if(cities){
      setAllCities(JSON.parse(cities));
    }
  }, []);

  // Save the records to localStorage whenever they change
  useEffect(() => {
    if (records.length > 0) {
      localStorage.setItem("records", JSON.stringify(records));
    }
  }, [records]);

  const openModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const toggleSelectAll = () => {
    setAreAllSelected((prevAreAllSelected) => {
      const newAreAllSelected = !prevAreAllSelected;

      if (newAreAllSelected) {
        const newSelectedRecords = records.map((record) => record.providerId);
        dispatch(setSelectedRecords(newSelectedRecords));
      } else {
        dispatch(setSelectedRecords([]));
      }

      return newAreAllSelected;
    });
  };

  const handleSelectedChange = (providerId, isSelected) => {
    if (isSelected) {
      const newSelectedRecords = [...selectedRecords, providerId];
      dispatch(setSelectedRecords(newSelectedRecords));
    } else {
      const newSelectedRecords = selectedRecords.filter(
        (id) => id !== providerId
      );
      dispatch(setSelectedRecords(newSelectedRecords));
    }
  };

  useEffect(() => {
    // Fetch the list of cities from the server
    const fetchCities = async () => {
      try {
        const response = await fetch(`${API_HOST}/cities`);
        if (response.ok) {
          const data = await response.json();
          if (data.data && Array.isArray(data.data)) {
            sessionStorage.setItem("cities", JSON.stringify(data.data));
          }
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    if (!allCities || allCities.length === 0) {
      fetchCities();
    }
  }, [dispatch]);

  useEffect(() => {
    // Filter cities based on the cityInput value
    let filtered = [];
    if (allCities && cityInput) {
      if (cityInput.trim() !== "") {
        filtered = allCities.filter((city) =>{
            if(city.cityName){
              return city.cityName.toLowerCase().includes(cityInput.toLowerCase());
            }
            else if(city.districtName){
              return city.districtName.toLowerCase().includes(cityInput.toLowerCase());
            }
          }
        );
      }
    }
    setFilteredCities(filtered);
  }, [cityInput, allCities]);

  const handleCityInputChange = (event) => {
    const value = event.target.value;
    setCityInput(value);
    setSelectedCity("");
    setShowList(value.trim() !== "");
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    dispatch(setArea(city));
    setCityInput(city.cityName);
    setShowList(false);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    const data = await fetchRecords(selectedCity);
    setRecords(data);
    setIsLoading(false);
  };

  return (
    <>
      <div className="bg-gray-100 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center">
            <button
              className="mt-3 md:mt-0 px-2 sm:px-6 py-1 md:py-2 bg-blue-500 text-sm sm:text-base text-white rounded-full shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2 sm:mr-4 mb-4 sm:mb-0"
              onClick={handleSearch}
              disabled={isLoading}
            >
              {isLoading ? <div className="loader"></div> : "חפש"}
            </button>
            <div className="relative">
              <input
                type="text"
                placeholder="חפש עיר"
                value={cityInput}
                onChange={handleCityInputChange}
                className="w-64 sm:w-96 px-2 sm:px-4 py-2 text-xs sm:text-base text-right bg-white border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {showList && filteredCities.length > 0 && (
                <ul className="absolute left-0 mt-2 w-96 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                  {filteredCities.map((city) => (
                    <li
                      key={city}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleCitySelect(city)}
                    >
                      {city.cityName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {records.length > 0 && (
          <div className="flex items-center justify-end mt-4">
            <div>
              <button
                onClick={openModal}
                className={`px-6 py-2 text-white rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 mr-4 ${
                  selectedRecords.length > 0
                    ? "bg-green-500 hover:bg-green-600 focus:ring-green-500"
                    : "bg-green-200 cursor-not-allowed"
                }`}
                disabled={selectedRecords.length === 0}
                title={
                  selectedRecords.length > 0
                    ? `נבחרו ${selectedRecords.length} ספקים`
                    : "בחר לפחות ספק אחד לקבלת הצעת מחיר"
                }
              >
                קבל הצעת מחיר
              </button>
              <button
                className="px-6 py-2 bg-blue-700 text-white rounded-full shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2"
                onClick={toggleSelectAll}
              >
                {areAllSelected ? "נקה בחירה" : "בחר הכל"}
              </button>
            </div>
          </div>
        )}
        <hr className="border-t border-gray-300 my-8 " />
        <div>
          {records.map((record, index) => (
            <Record
              key={index}
              professional={record}
              isLoggedIn={isLoggedIn}
              isAllSelected={areAllSelected}
              onSelectedChange={handleSelectedChange}
            />
          ))}
        </div>
        {!isLoggedIn && showModal && (
          <Modal>
            <div className="flex items-center justify-center h-full">
              <LoginSignup
                style={{ maxWidth: "32rem", width: "100%" }}
                handleClose={handleClose}
              />
            </div>
          </Modal>
        )}
        {isLoggedIn && showModal && (
          <Modal className="w-3/4! mx-auto">
            <div className="flex items-center justify-center h-full">
              <RequestForm
                className="w-full"
                handleClose={() => handleClose()}
                recordIdContext={null}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default MainPage;
