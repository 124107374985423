// SideButtons.jsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

const SideButtons = ({sendAction}) => {
    const selectedRecords = useSelector((state) => state.selectedRecordIds); // Moved to the top level

    const [showButton, setShowButton] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const checkScroll = () => {
            if (window.scrollY > 0 && selectedRecords.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('scroll', checkScroll);
        window.addEventListener('resize', handleResize);

        if (window.scrollY > 0 && selectedRecords.length > 0) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }

        return () => {
            window.removeEventListener('scroll', checkScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [selectedRecords]);

    const buttonVariants = {
        hidden: { x: '100vw' },
        visible: { 
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 60,
                damping: 10
            }
        }
    };

    return (
        <div>
            {showButton && (
                <div className="relative">
                    <motion.button
                        className="fixed top-1/2 transform translate-y-[-50%] right-5 z-50 text-white font-bold rounded-full"
                        style={{ 
                            backgroundColor: '#22C55E', 
                            width: windowWidth <= 768 ? '4rem' : '6rem', 
                            height: windowWidth <= 768 ? '4rem' : '6rem',
                            fontSize: windowWidth <= 768 ? '0.75rem' : '1rem'
                        }}
                        variants={buttonVariants}
                        initial="hidden"
                        animate="visible"
                        onClick={()=>sendAction(null)} 

                    >
                        {selectedRecords.length > 1 ? `שלח ל-${selectedRecords.length} הנבחרים` : "שלח לנבחר"}
                    </motion.button>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40">
                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideButtons;