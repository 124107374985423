import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProviderById } from "../redux_slices/providerActions";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux_slices/userSlice";
import { fetchUser } from "../redux_slices/userActions";
import Stars from "./Stars";
import Reviews, { SORT_OPTIONS } from "./Reviews";

const DESCRIPTION_LIMIT = 2000;

function ProviderDetails() {
  const { providerId } = useParams();
  const [provider, setProvider] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [sortOption, setSortOption] = useState("האחרונים ביותר");
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user); // Retrieve the user from the Redux store

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    if (!user && authToken) {
      // Check for an existing auth token
      const fetchUserData = async () => {
        const data = await fetchUser(authToken);
        dispatch(setUser(data.data.user));
      };

      fetchUserData();
    }
    const fetchProvider = async () => {
      setIsLoading(true); // Add this line
      const data = await fetchProviderById(providerId);
      setProvider(data);
      setIsLoading(false);
    };
    if (!provider) fetchProvider();
  }, [dispatch, user, providerId, provider]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader w-16 h-16 border-8 border-t-8 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  let desc;
  if (provider) {
    desc = provider.dpFullDescription
      ? provider.dpFullDescription
      : provider.description;
  }
  // ...

  return (
    <div className="mt-12 m-5 text-right">
<div className="float-right ml-10 sm:mx-auto lg:float-right lg:ml-10" style={{ width: "400px", maxWidth: "100%" }}>        <img
          src={provider.img}
          alt={provider.name}
          className="object-cover rounded-lg shadow-lg w-full h-full"
        />
        <Stars
          score={provider.reviewScore}
          reviewCount={provider.reviewCount}
          providerId={provider.providerId}
        />
      </div>
      <h1 className="text-2xl font-bold text-blue-700" dir="rtl">
        {provider.firstName},&nbsp;&nbsp;&nbsp;{provider.businessName}
      </h1>
      <p
        className="mt-8 text-lg text-gray-700"
        dangerouslySetInnerHTML={{
          __html: isExpanded ? desc : desc.substring(0, DESCRIPTION_LIMIT),
        }}
      />
      {desc && desc.length > DESCRIPTION_LIMIT && !isExpanded && (
        <button
          onClick={() => setIsExpanded(true)}
          className="text-blue-500 hover:underline mt-2"
        >
          קרא עוד
        </button>
      )}
      {isExpanded && (
        <button
          onClick={() => setIsExpanded(false)}
          className="text-blue-500 hover:underline mt-2"
        >
          קרא פחות
        </button>
      )}
      <h2 className="text-xl font-bold text-blue-700 mt-5" dir="rtl">
        חוות דעת
      </h2>
      <div className="mt-2">
        <h3 className="text-lg font-bold text-gray-700" dir="rtl">
          מיין לפי :
        </h3>
        <select
          className="mt-1 p-2 border border-gray-300 rounded-md bg-white text-gray-700"
          onChange={handleSortChange}
        >
          {Object.values(SORT_OPTIONS).map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
      </div>
      <div className="mt-5">
        <Reviews sortOption={sortOption} providerId={providerId} />
      </div>
    </div>
  );
}

export default ProviderDetails;
