// Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';

const initialState = { step: 1 };

export const flowSlice = createSlice({
    name: 'flow',
    initialState,
    reducers: {
        advanceFlow: (state, action) => {
            state.step = action.payload;
            console.log('Updated step:', state.step); // Log the updated step value
        },       
    },
});

export const { advanceFlow } = flowSlice.actions;

export default flowSlice.reducer;