// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => action.payload,
        setHasChatAccount: (state, action) => {
            if (state) {
                state.hasChatAccount = action.payload;
            }
        },
        setHasUnreadMessages: (state, action) => {
            if (state) {
                state.hasUnreadMessage = action.payload;
                console.log('setMessagesRead action called, new state:', state);
            }
        }
    },
});

export const { setUser,setHasChatAccount, setHasUnreadMessages} = userSlice.actions;

export default userSlice.reducer;