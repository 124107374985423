import React from "react";
import { useDispatch } from "react-redux";
import { advanceFlow } from "../redux_slices/flowSlice"; // Import the action that decreases the step

const BottomFlowSection = ({ step, isNextDisabled }) => {
  const dispatch = useDispatch(); // Get the dispatch function

  return (
    <div
      dir="rtl"
      className="fixed bottom-0 left-0 right-0 flex justify-center bg-gray-200 p-4"
    >
      <button
        className={`mx-2 p-2 rounded w-1/6 h-10 text-white ${isNextDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-600'}`}
        onClick={() => dispatch(advanceFlow(step + 1))}
        disabled={isNextDisabled}
      >
        הבא
      </button>
      <button
        className="mx-2 bg-white text-gray-500 p-2 rounded w-1/6 h-10"
        onClick={() => dispatch(advanceFlow(step - 1))} // Dispatch the action when the back button is clicked
      >
        הקודם
      </button>
    </div>
  );
};

export default BottomFlowSection;
