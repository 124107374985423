import React, { useEffect, useState } from "react";
import { fetchRecheckUnreadMessages, loginUser } from "../redux_slices/userActions";
import { useDispatch } from "react-redux";
import { setHasUnreadMessages, setUser } from "../redux_slices/userSlice";
import StatusCode from "../utility/StatusCode";
import Cookies from "js-cookie";
import { loginWithGoogle } from "../fetch/loginWithGoogleFetch";
import { useNavigate } from "react-router-dom";
import setCookieToUpperLevel from "../cookies/CookiesSetter";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const  navigate  = useNavigate();

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setErrorMessage("נא למלא את כל השדות");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("נא להזין כתובת דואר אלקטרוני תקינה");
      return;
    }

    try {
      const { result, statusCode }  = await loginUser(email, password);

      if (statusCode === StatusCode.OK) {
        setCookieToUpperLevel(result.user.firstName, result.user.avatar);

        // Login successful, handle the User object
        console.log("User:", result);
        dispatch(setUser(result.user));
        if(!result.user.hasUnreadMessage){
          const authToken = localStorage.getItem("authToken");
          fetchRecheckUnreadMessages(authToken).then(
            (hasUnreadMessage) => {
              dispatch(setHasUnreadMessages(hasUnreadMessage));
            }
          ).catch(error => {
            console.error('Error fetching additional data:', error);
          });
        }
        
      } else {
        if (statusCode === StatusCode.UNAUTHORIZED) {
          setErrorMessage("כתובת דואר אלקטרוני או סיסמה שגויים!");
        } else if (result.hasOwnProperty("error")) {
          // The 'error' attribute exists in the 'data' object
          setErrorMessage(result.error);
        } else {
          // The 'error' attribute does not exist in the 'data' object
          setErrorMessage("Login failed. Please check your credentials.");
        }
      }
    } catch (error) {
      // Handle network or other errors
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id:
            "928594069070-4cf7qmsujlvaeu78s3f3juvfl6rn1frg.apps.googleusercontent.com",
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("googleButton"),
          { theme: "outline", size: "large" } // customization attributes
        );
        window.google.accounts.id.prompt(); // also display the One Tap dialog
  
        // Clear the interval once the Google One Tap library is loaded
        clearInterval(intervalId);
      }
    }, 100); // Check every 100ms
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function handleCredentialResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
    loginWithGoogle(response.credential).then((backendResult) => {
      console.log("result: ", backendResult);
      if (backendResult.data.data.user.tos) {
        console.log("User:", backendResult.data.data.user.firstName);
        // The user has accepted the terms of service.
        // Dispatch an action to store the user in the Redux store.
        dispatch(setUser(backendResult.data.data.user));
        setCookieToUpperLevel(backendResult.data.data.user.firstName, backendResult.data.data.user.avatar);
        const base64Token = btoa(backendResult.data.data.token); // Encode the token in base64

        localStorage.setItem("authToken", base64Token);
      } else {
        // The user has not accepted the terms of service.
        // Redirect them to a page where they can accept the terms of service.
        console.log('User has not accepted the terms of service')
        navigate('/terms-of-service');
    
      }
   
    })};

  return (
    <section className="vh-100" dir="rtl">
      <div className="container py-5 h-100">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
              className="img-fluid"
              alt="Phone image"
            />
          </div>
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
            <form onSubmit={handleSubmit}>
              <div data-mdb-input-init className="form-outline mb-4">
                <input
                  type="email"
                  id="form1Example13"
                  className="form-control form-control-lg"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setErrorMessage('')}}
                />
                <label className="form-label" htmlFor="form1Example13">
                  אימייל
                </label>
              </div>

              <div data-mdb-input-init className="form-outline mb-4">
                <input
                  type="password"
                  id="form1Example23"
                  className="form-control form-control-lg"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setErrorMessage('')}}
                />
                <label className="form-label" htmlFor="form1Example23">
                  סיסמה
                </label>
              </div>

              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

              <div className="d-flex justify-content-around align-items-center mb-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="form1Example3"
                    checked
                  />
                  <label className="form-check-label" htmlFor="form1Example3">
                    זכור אותי
                  </label>
                </div>
                <a href="#!">שכחת סיסמה?</a>
              </div>

              <button
                type="submit"
                data-mdb-button-init
                data-mdb-ripple-init
                className="btn btn-primary btn-lg btn-block"
              >
                התחבר
              </button>

              <div className="divider d-flex align-items-center my-4">
                <p className="text-center fw-bold mx-3 mb-0 text-muted">או</p>
              </div>

              <div id="googleButton" className="w-full"></div>

              <button
                type="button"
                className="text-lg text-black bg-white hover:bg-blue-700 border border-gray-300 w-full py-2 mt-3"
              >
                שלח לי קישור להתחברות באימייל
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
