import { API_HOST } from "../config/config";

export async function saveRequest(authToken, request) {
  try {
    const response = await fetch(`${API_HOST}/save-request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(request),
    });

    console.log("Request saved successfully!");
    const data = await response.json();
    return [data, response.status];
  } catch (error) {
    console.error("An error occurred while saving the request:", error);
  }
}   
