import React from "react";
import { useSelector } from "react-redux";
import FlowLoading6 from "./FlowLoading.jsx";
import { useNavigate } from "react-router-dom";
import FlowTestShortcut from "./FlowTestShortcut.jsx";

const FlowTest = () => {
  console.log("Flow: rendering");
  const step = useSelector((state) => state.flow.step);
  const requestId = useSelector((state) => state.currentRequest.requestId);
  const navigate = useNavigate();

  console.log("bla bla bla");
  switch (step) {
    case 1:      
      return <FlowTestShortcut/>;
    case 6:
      return <FlowLoading6 step={6}/>;
    case 7:
      navigate(`/pros_list`+`?requestId=`+requestId);
      return;
    default:
      return <div>Invalid step</div>;
  }
};

export default FlowTest;

