import * as React from 'react';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useDispatch, useSelector } from 'react-redux';
import { API_HOST } from '../config/config';
import { advanceFlow } from '../redux_slices/flowSlice';
import { setUser } from '../redux_slices/userSlice';
import { setCurrentRequest } from '../redux_slices/currentRequestSlice';
import Cookies from 'js-cookie';

const FlowLoading6 = ({step}) => {

    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const currentRequest = useSelector((state) => state.currentRequest);


    //sign up , log in and set the request return the user.
    useEffect(() => {
        const signUpAndLoginWithRequest = async () => {

            try {
                // Send a request to the backend to signup-login and save the request     
                                                           
                const response = await fetch(`${API_HOST}/signup-login-save-request`, {
                    method: 'POST',
                    body: JSON.stringify(currentRequest),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch(setUser(data.data.user));
                    dispatch(setCurrentRequest(data.data.request))
                    //save token to local storage
                    const token = data.data.token;
                    const base64Token = btoa(token); // Encode the token in base64
                    localStorage.setItem("authToken", base64Token);

                    } else {
                    // Handle error response
                    console.error('Failed to signup-login:', response.status);
                }
            } catch (error) {
                // Handle network or other errors
                console.error('Error during signup-login:', error);
            }
        };  

        signUpAndLoginWithRequest();
    }, [dispatch]);;

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {

                    clearInterval(timer); // Clear the interval
                    setTimeout(() => dispatch(advanceFlow(step+1)), 1000); // Dispatch the action after a delay

                    return 100;
                }
                const diff = 0.6;
                return Math.min(oldProgress + diff, 100);
            });
        }, 20); // Update progress every 5ms to reach 100% in approximately 2 seconds
    
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', flexDirection: 'column' }}>
            <div style={{ maxWidth: '600px', width: '100%', textAlign: 'center' }}>
                <h2 style={{ marginBottom: '20px', fontSize: '2em', fontWeight: 'bold' }}>אנחנו מחפשים את המקצוענים הטובים ביותר עבור הפרויקט שלך</h2>
            </div>
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" value={progress} size={120} /> {/* Use CircularProgress with controlled value */}
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <BusinessCenterIcon style={{ fontSize: 80 }} /> {/* Reduce the size of the BusinessCenterIcon */}
                </Box>
            </Box>
        </div>
    );
};

export default FlowLoading6;