import { API_HOST } from "../config/config";

export const fetchProvider = async (userId) => {
  try {
    const response = await fetch(
      `${API_HOST}/providers/getProviderFullDetails?userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      const provider = data.data;
      return provider;
    }
  } catch (error) {
    console.error("Error fetching user:", error);
  }
  return null;
};

export const fetchProviderById = async (providerId) => {
  try {
    const response = await fetch(`${API_HOST}/providers/${providerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const data = await response.json();
      const provider = data.data;
      return provider;
    }
  } catch (error) {
    console.error("Error fetching provider:", error);
  }
  return null;
};
