import { Link } from "react-router-dom";

const renderStars = (score) => {
  const filledStars = Math.floor(score);
  const hasHalfStar = score - filledStars >= 0.5;
  const stars = [];

  // Add filled stars
  for (let i = 0; i < filledStars; i++) {
    stars.push(
      <span key={i} className="text-yellow-500">
        &#9733;
      </span>
    );
  }

  // Add half star
  if (hasHalfStar) {
    stars.push(
      <span key="half-star" className="text-yellow-500">
        &#9734;&#xFE0E;
      </span>
    );
  }

  // Add empty stars
  for (let i = stars.length; i < 5; i++) {
    stars.push(
      <span key={i} className="text-gray-400">
        &#9734;&#xFE0E;
      </span>
    );
  }

  return stars;
};

const Stars = ({ score, reviewCount, providerId }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex mt-2">{renderStars(score)}</div>
      {/* Display review count */}
      <div>
        {providerId && reviewCount && (
          <Link to={`/providers/${providerId}`} className="text-blue-600">
            {reviewCount} ביקורות{" "}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Stars;
