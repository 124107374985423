import React, { useState } from "react";
import CreateNewRequest from "./CreateNewRequest";
import UseExistingRequest from "./UseExistingRequest";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom'; 

function RequestForm({ handleClose, recordIdContext }) {
  const [activeTab, setActiveTab] = useState("create");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate(); 


  function handleFinished(success) {
    if (success) {
      setIsSuccessModalOpen(true);
    }
    else{
      handleClose();
    }
  }

  if(isSuccessModalOpen){
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CheckCircleIcon style={{ color: 'green', fontSize: 50, marginBottom: '10px' }} />
          <h2 style={{ color: 'green', marginBottom: '30px' }}>ההודעה נשלחה בהצלחה</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px' }}>
            <button style={{ backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px', padding: '10px', marginBottom: '10px', cursor: 'pointer', transition: 'background-color 0.3s', height: '40px', marginRight: '10px', width: '140px' }} onClick={handleClose} onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'blue'}>סגור</button>
            <button style={{ backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px', padding: '10px', cursor: 'pointer', transition: 'background-color 0.3s', height: '40px', width: '140px' }} onClick={() => navigate('/messages')} onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'blue'}>צפה בהודעות</button> 
          </div>
        </div>
      )
  }

  return (
    <div>
      <button
        className={`px-4 py-2 ml-2 ${
          activeTab === "create"
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-700"
        }`}
        onClick={() => setActiveTab("create")}
      >
        צור בקשה חדשה
      </button>
      <button
        className={`px-4 py-2 ml-2 ${
          activeTab === "useExisting"
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-700"
        }`}
        onClick={() => setActiveTab("useExisting")}
      >
        השתמש בבקשה קיימת
      </button>

      {activeTab === "create" && <CreateNewRequest handleFinished={handleFinished} recordIdContext={recordIdContext} />}
      {activeTab === "useExisting" && (
        <UseExistingRequest
          handleFinished={handleFinished}
          recordIdContext={recordIdContext}
        />
      )}
    </div>
  );
}

export default RequestForm;
