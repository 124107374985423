import { API_HOST } from "../config/config";

export const fetchCategories = async () => {
  const storedCategories = localStorage.getItem('categories');
  const storedTimestamp = localStorage.getItem('categories_timestamp');

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const now = new Date();

  // Check if we have saved data and it's less than a day old
  if (storedCategories && storedTimestamp && now - new Date(storedTimestamp) < oneDay && storedCategories.length > 0) {
    return JSON.parse(storedCategories);
  }

  // Fetch new data
  const response = await fetch(`${API_HOST}/categories`);
  const data = await response.json();

  // Save the data to LocalStorage along with the current timestamp
  localStorage.setItem('categories', JSON.stringify(data.data));
  localStorage.setItem('categories_timestamp', now.toString());

  return data.data;
};