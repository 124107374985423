import React, { useState, useEffect } from "react";
import Stars from "./Stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import Button from "@mui/material/Button";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";

const Record = ({
  professional,
  isAllSelected,
  onSelectedChange,
  sendAction
}) => {
  const [isSelected, setSelected] = useState(isAllSelected);

  useEffect(() => {
    setSelected(isAllSelected);
  }, [isAllSelected]);





  const handleSelectionChange = () => {
    const newSelectedState = !isSelected;

    setSelected(newSelectedState);
    onSelectedChange(professional.providerId, newSelectedState); // Call onSelectedChange with the id of the professional and the new selected state
  };

  return (
    <div className="record border-gray-300 rounded-md p-4 mb-4 flex flex-col md:flex-row items-center justify-between relative w-full">
      <div
        className="flex flex-col items-center md:mb-0 mb-4 ml-8" // Increased ml-8 for more margin-left
        style={{ minWidth: "150px" }}
      >
        <div
          className="rounded-lg w-32 h-32 md:w-44 md:h-44 mt-4 md:mt-0"
          style={{
            backgroundImage: `url(${professional.img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img
            src={professional.img}
            alt={professional.firstName}
            className="hidden"
          />
          <div className="absolute top-0 right-0 ">
            <Button
              startIcon={
                <FontAwesomeIcon
                  icon={faIdCard}
                  className="text-blue-400"
                  style={{
                    fontSize: "1em",
                    marginLeft: "0.7em",
                  }}
                />
              }
            >
              רשיון מקצועי
            </Button>
            <Button
              startIcon={
                <WorkHistoryOutlinedIcon
                  style={{
                    color: "#3B82F6",
                    marginLeft: "0.7em",
                  }}
                />
              }
              style={{ color: "#3B82F6" }}
            >
              מעל 10 שנות נסיון
            </Button>
          </div>
        </div>
        <Stars
          score={professional.reviewScore}
          reviewCount={professional.reviewCount}
          providerId={professional.providerId}
        />
      </div>
      <div className="flex-1 flex flex-col justify-between w-full">
        <div className="text-right w-full min-h-[200px] mt-4">
          <h2 className="text-2xl font-semibold inline-block">
            {professional.firstName}
          </h2>
          <h3 className="text-xl text-gray-600">
            {professional.professionality}
          </h3>
          <p
            className="mt-2 text-gray-700 text-lg"
            dangerouslySetInnerHTML={{ __html: professional.description }}
          ></p>
        </div>
        <div
          className="flex items-center justify-end mt-4 w-full"
          style={{ minWidth: "150px" }}
        >
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded truncate w-full sm:w-64 sm:h-auto" // Removed h-12
            dir="rtl"
            onClick={()=>sendAction(professional.providerId)}
            style={{ marginLeft: "10px" }} // Use marginLeft here
          >
            <span>קבל ממני הצעת מחיר</span>
          </button>
          <button
            className={`ml-2 py-2 px-4 rounded ${
              isSelected ? "bg-green-500" : "bg-gray-300"
            } w-full sm:w-auto`}
            onClick={(handleSelectionChange)}
          >
            {isSelected ? "בטל בחירה" : "בחר אותי"}
          </button>
        </div>
      </div>     
    </div>
  );
};

export default Record;
